<template>
  <div class="user-card">
    <div class="d-flex flex-column align-items-center mr-2">
      <div class="user-avatar" :class="classesList">
        <img v-if="dp" :src="dp" alt="Avatar" style="width: 40px;height: 40px;"/>
        <span v-else class="text-uppercase">{{ initials }}</span>
      </div>
      <small class="d-block mt-1">
        <label :for="'dp_in_'+randomNumber" class="text-primary" v-on:click="isImagePopupOpen=true">{{ $t('Upload Photo') }}</label>
      </small>
    </div>
    <div class="user-info text-left rounded p-2 bg-lighter flex-grow-1">
      <span class="lead-text">{{ userName }} <span class="dot dot-success d-md-none ml-1"></span></span>
      <div class="lh-n" v-if="company">{{company}}</div>
      <div :class="{'lh-n': !company}">{{ postCode }}, {{ city }} {{ province.length ? ', '+province : '' }}</div>
      <row class="mt-1">
        <column class="col-auto">
          <nk-button size="xs" type="success" :is-icon-only-button="true" class="h-100" style="z-index: 1">
            <nio-icon icon="ni-call"></nio-icon>
          </nk-button>
          <badge type="success" :outline="true" :dim="true" :content="phone" class="h-100 ml-n1 pl-2 align-items-center"></badge>
        </column>
        <column class="col-auto mt-1 mt-md-0">
          <nk-button size="xs" type="primary" :is-icon-only-button="true" class="h-100" style="z-index: 1">
            <nio-icon icon="ni-mail"></nio-icon>
          </nk-button>
          <badge type="primary" :outline="true" :dim="true" :content="userEmail" class="h-100 ml-n1 pl-2 align-items-center"></badge>
        </column>
      </row>
    </div>
  </div>
  <image-upload @on-receive-blob="dpSelected" @on-modal-close="isImagePopupOpen=false" :modal-open="isImagePopupOpen"></image-upload>
</template>

<script>
import {Row, Column} from "@core/layouts";
import { Badge } from "@core/components";
import {ref} from "vue";
import ImageUpload from "@core/components/image-upload/ImageUpload";

export default {
  name: "UserCardVariant2",
  components: {
    Badge,
    ImageUpload,
    Row,
    Column
  },
  emits: ['onImageChange'],
  props: {
    initials: String,
    image: String,
    bgColor: {
      type: String,
      default: 'primary',
    },
    bgDim: Boolean,
    userEmail: String,
    phone: String,
    postCode: String,
    city: String,
    company: String,
    province: String,
    userName: {
      type: String,
      required: true,
    }
  },
  setup(props, {emit}){

    let dp = ref(props.image)
    let isImagePopupOpen = ref(false)

    let classesList = []
    if( props.image){
      classesList.push(`bg-transparent`)
    }
    else{
      if( props.bgDim ){
        classesList.push(`bg-dim-${props.bgColor}`)
      }
      else {
        classesList.push(`bg-${props.bgColor}`)
      }
    }
    let randomNumber = Math.random()

    const dpSelected = (blob) => {
      let reader = new FileReader();
      reader.onload = function(e) {
        dp.value = e.target.result
      }
      reader.readAsDataURL(blob);
      emit('onImageChange', blob)
    }

    return {
      classesList,
      isImagePopupOpen,
      randomNumber,
      dpSelected,
      dp,
    }
  }
}
</script>

<style scoped>
.rounded-left-only{
  border-radius: 4px 0 0 4px!important;
}
.rounded-right-only{
  border-radius: 0 4px 4px 0!important;
}
</style>
