<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Email')+' '+$t('Templates')" :description="$t('Total')+' '+pagination.totalRecords+' '+$t('templates')+'.'">
        <block-tools>
          <li>
            <block-search :placeholder="$t('Search')+' '+$t('template')" v-model="payload.search"></block-search>
          </li>
          <li>
            <drop-down title="" trigger-btn-classes="dropdown-indicator btn-outline-light btn-white"
                       :trigger-btn-text="$t('Category')+' '+$t('Filter')"
                       :dot="parseInt(payload.cat_filter) > 0 ? 'primary' : ''">
              <ul class="link-list-opt no-bdr">
                <list-item
                    v-on:click="[payload.page=1,payload.cat_filter=0,F_getTemplates(true)]"
                    :text="$t('All')"
                    :class="{active: 0 === payload.cat_filter}"></list-item>
                <list-item v-for="cat in tCategories"
                           :key="'tc'+cat.id" :text="cat.cat_name"
                           :class="{active: cat.id === payload.cat_filter}"
                           v-on:click="[payload.page=1, payload.cat_filter=cat.id, F_getTemplates(true)]"></list-item>
              </ul>
            </drop-down>
          </li>
          <li>
            <drop-down icon="ni ni-setting" title="" size="xs" direction="right" trigger-btn-classes="btn-white btn-outline-light" :dot="payload.order != 'desc' ? 'primary' : ''">
              <ul class="link-check">
                <li><span>{{$t('Order')}}</span></li>
                <li :class="{active: payload.order === 'asc'}"><a href="javascript:;" v-on:click="[payload.page=1,payload.order='asc', F_getTemplates(true)]">{{$t('ASC')}}</a></li>
                <li :class="{active: payload.order === 'desc'}"><a href="javascript:;" v-on:click="[payload.page=1,payload.order='desc', F_getTemplates(true)]">{{$t('DESC')}}</a></li>
              </ul>
            </drop-down>
          </li>
          <li>
            <nk-button type="primary" is-icon-only-button="" v-on:click="$router.push({name: 'adminEmailTemplateViewEdit', params:{id: 'new'}})">
              <nio-icon icon="ni-plus"></nio-icon>
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <div class="d-flex py-5 justify-center"  v-if="loadingData">
          <ion-spinner></ion-spinner>
        </div>
        <template v-else>
          <div v-if="eTemplates.length < 1" class="alert alert-light alert-icon">
            <nio-icon icon="ni-alert"></nio-icon>
            <div v-if="payload.search || payload.cat_filter">{{ $t('No template found for this criteria.') }}</div>
            <div v-else>{{ $t('No template added yet.') }}</div>
          </div>
          <row class="g-gs" v-else>
            <column :sm="6" :lg="4" :xxl="3" v-for="eTemplate in eTemplates" :key="'et'+eTemplate.id">
              <project-card>
                <template v-slot:head>
                  <project-card-title
                      :title-text="eTemplate.name"
                      :sub-text="eTemplate.copy_of === 0 ? $t('Builtin') : $t('Your own created')"
                      :to="{name: 'adminEmailTemplateViewEdit', params: {id: eTemplate.id}}" :avatar-text="eTemplate.id"></project-card-title>
                  <drop-down-list btn-size="sm">
                    <!-- edit if user is admin or if its user copied template -->
                    <list-item icon="ni-eye"
                               v-if="userRole === 'is-admin' || eTemplate.copy_of > 0"
                               :text="$t('View')+'/'+$t('edit')" :to="{name: 'adminEmailTemplateViewEdit', params: {id: eTemplate.id}}"></list-item>
                    <list-item icon="ni-activity-round"
                               v-else-if="userRole === 'is-advisor'"
                               :text="$t('Copy')"></list-item>
                    <list-item icon="ni-delete" v-on:click="F_deleteTemplate(eTemplate.id)"
                               v-if="userRole === 'is-admin' || eTemplate.copy_of > 0"
                               :text="$t('Delete')"></list-item>
                  </drop-down-list>
                </template>
                <template v-slot:details>
                  <p>{{eTemplate.info}}</p>
                </template>
              </project-card>
            </column>
          </row>
          <div class="card-inner px-0" v-if="pagination.filteredTotal > 10">
            <pagination
                v-model="payload.page"
                :records="pagination.filteredTotal"
                :per-page="10"
                @paginate="paginationCallback"
                theme="bootstrap4"
                :options="{chunk: 5, texts:{count: '||'}}"/>
          </div>
        </template>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {IonSpinner} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, BlockSearch, BlockTools, MainBlock} from "@core/layouts";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import {ProjectCard, ProjectCardTitle} from "@/views/components";
import useTemplateFunctions from "./eTemplatesFunctions"
import PageTemplate from "@core/layouts/page/PageTemplate";


export default defineComponent({
  components: {
    PageTemplate,
    BlockSearch,
    BlockTools,
    ProjectCard,
    ProjectCardTitle,
    ListItem,
    DropDownList,
    BlockHead,
    Column,
    Row,
    DropDown,
    NkButton,
    NioIcon,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
  },
  setup() {

    const router  = useRouter()
    const store   = useStore()
    const { updateTracker } = useDataChangeTracker()
    const { deleteTemplate, getCategories, getTemplates } = useTemplateFunctions()
    const leadStatuses = store.state.general_data.leads_statuses
    const userRole = ref(store.state.auth.user_role)

    let payload = reactive({
      cat_filter: '',
      order: 'desc',
      page: 1,
      search: '',
    })

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let eTemplates     = ref([])
    let tCategories    = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      totalRecords: 0,
      filteredTotal: 0,
    })
    const paginationCallback = async () => {
      await F_getTemplates(true)
    }

    const F_getTemplates = async (reset) => {
      loadingData.value = true
      getTemplates(payload)
      .then( r => {
        pagination.filteredTotal = r.total_filtered
        if(reset){
          eTemplates.value = r.data
        }
        else{
          eTemplates.value = eTemplates.value.concat(r.data)
        }
        if(payload.page === 1){
          pagination.totalRecords = r.total_records
        }
      })
      .catch( er => {
        console.log('TemplatesList.vue ajax error: ', er)
      })
      .then(() => loadingData.value = false)
    }

    watch(()=> payload.search, () => {
      payload.page = 1
      F_getTemplates(true)
    })

    onMounted(()=> {
      getCategories().then(data=>{
        for(let x in data.data){
          tCategories.value.push(data.data[x])
        }
      })
      F_getTemplates()
    })

    const F_deleteTemplate = async ( id ) => {
      deleteTemplate(id)
      .then(() => {
        for(let x in eTemplates.value){
          if(id === eTemplates.value[x].id){
            eTemplates.value.splice(x, 1)
          }
        }
      })
      .catch()
    }

    // Update templates list, in case changes are made within in other components
    watch(() => store.state.dataChangeTracker.email_templates, (n) => {
      if(n){
        updateTracker(false, ['email_templates'], false) // reset tracker
        F_getTemplates(true)
      }
    })

    return {
      leadStatuses,
      eTemplates,
      F_deleteTemplate,
      F_getTemplates,
      loadingData,
      router,

      payload,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      userRole,
      tCategories,
    }
  }
});

</script>
