<template>
  <card full-height>
    <div class="project">
      <div class="project-head align-items-start">
        <slot name="head"></slot>
      </div>
      <div class="project-details text-muted">
        <slot name="details"></slot>
      </div>
      <div class="project-progress" v-if="progressPercentage">
        <div class="project-progress-details">
          <!--<div class="project-progress-task"><em class="icon ni ni-check-round-cut"></em><span>3 Tasks</span></div>-->
          <div class="project-progress-percent">{{progressPercentage}}%</div>
        </div>
        <div class="progress progress-pill progress-md bg-light">
          <div class="progress-bar" v-bind:data-progress="progressPercentage"></div>
        </div>
      </div>
      <div class="project-meta">
        <slot name="meta"></slot>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@core/components/cards/Card";
export default {
  components: {Card},
  props: {
    progressPercentage: Number,
  }
}
</script>
