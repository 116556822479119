<template>
  <router-link :to="to" class="project-title">
    <div class="user-avatar sq" v-if="avatarText" :class="'bg-'+pickRandColor()"><span>{{ avatarText }}</span></div>
    <div class="project-info">
      <h6 class="title">{{ titleText }}</h6>
      <span class="sub-text">{{ subText }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: null,
    titleText: String,
    subText: String,
    avatarText: null,
  },
  setup(){
    const colors = ['purple','orange', 'danger', 'secondary', 'success']
    const pickRandColor = () => {
      return colors[colors.length * Math.random() | 0];
    }

    return{
      pickRandColor,
    }
  },
}
</script>

<style scoped>
.project-title .title{
  font-size: .8rem;
}
</style>